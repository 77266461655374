<template>
  <ion-list>
    <ion-list-header>
      Les métiers qui te font envie  ❤️
    </ion-list-header>
    <ion-item-sliding
      v-for="metier in metiers"
      :key="metier.id"
      @ionSwipe="toggleFavori(metier.id)"
    >
      <ion-item
        lines="full"
        @click="$router.push({name: 'ficheMetier', params: { id: metier.id } })"
      >
        <ion-icon
                  slot="start"
                  color="primary"
                  :icon="starSharp"></ion-icon>
        <ion-label class="ion-text-wrap">{{ metier.nom }}</ion-label>
      </ion-item>

      <ion-item-options side="end">
        <ion-item-option
          color="danger"
          @click="toggleFavori(metier.id)"
        >
          <ion-icon slot="icon-only" :icon="starHalfSharp"></ion-icon>
        </ion-item-option>
      </ion-item-options>

    </ion-item-sliding>
  </ion-list>
</template>

<script>
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonItemOption,
  IonItemOptions,
  IonItemSliding
} from "@ionic/vue";
import {
  starSharp, starHalfSharp
} from "ionicons/icons";
import {mapActions} from "vuex";

export default {
  name: 'ListeFavoris',
  setup() {
    return { starSharp, starHalfSharp };
  },
  components: {
    IonList,
    IonListHeader,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonIcon,
    IonLabel
  },
  props: {
    metiers: { required: true }
  },
  methods: {
    ...mapActions(['toggleFavori'])
  },
}
</script>

<style scoped>

</style>
