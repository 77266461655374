<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Favoris</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">

      <ListeFavoris
        v-if="getMetiersFavoris.length"
        :metiers="getMetiersFavoris"
      />
      <div class="vide" v-else>
        <div class="content">
          <h2>Pas encore de favoris ?</h2>
          <p>Parcours les métiers et tague ceux qui t'intéressent ❤️.</p>
        </div>

      </div>
      <created-by />
    </ion-content>
  </ion-page>
</template>
<script>

import ListeFavoris from '../components/ListeFavoris'
import CreatedBy from '@/components/CreatedBy'
import {mapGetters} from "vuex";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
export default {
  name: 'PageQualites',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    CreatedBy,
    ListeFavoris,
  },
  computed: {
    ...mapGetters(['getMetiersFavoris'])
  }
}
</script>

<style scoped>
  ion-list-header strong {
    color: var(--ion-color-primary);
    font-size: 1rem;
  }
  .vide .content {
    padding: 20px;
  }
</style>
